<div class="main-external" fxLayout="col" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center start">
  <div fxFlex="600px" fxFlex.lt-md="100%" class="login-panel mat-elevation-z24">
    <img src="assets/images/logo-howden.png" class="howden-logo" alt="Howden Logo" />
    <form [formGroup]="form" (ngSubmit)="updatePassword()">
      <h2 class="login-title" i18n="@@front-login.reset-pass.title">
        Solicitud de nueva contraseña
      </h2>
      <div class="field">
        <label class="label" i18n="@@front-login.reset-pass.new-password">Contraseña nueva</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD_NEW"
            i18n-placeholder="@@front-login.reset-pass.new-pass-placeholder"
            placeholder="Introduzca su contraseña nueva"
            type="password"
          />
          <mat-error
            i18n="@@front-login.reset-pass.new-password-required"
            *ngIf="form.get(C_PASSWORD_NEW)?.hasError('required')"
          >
            El campo contraseña nueva es obligatorio
          </mat-error>
          <mat-error *ngIf="form.get(C_PASSWORD_NEW)?.hasError('format')">
            {{ form.get(C_PASSWORD_NEW)?.getError('format').message }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field margin-top-20">
        <label class="label" i18n="@@front-login.reset-pass.repeat-new-password">Repetir contraseña nueva</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD_REPEAT"
            i18n-placeholder="@@front-login.reset-pass.repeat-pass-placeholder"
            placeholder="Introduzca de nuevo su contraseña nueva"
            type="password"
          />
          <mat-error
            i18n="@@front-login.reset-pass.repeat-password-required"
            *ngIf="form.get(C_PASSWORD_REPEAT)?.hasError('required')"
          >
            Es necesario repetir la contraseña nueva
          </mat-error>
          <mat-error *ngIf="form.get(C_PASSWORD_REPEAT)?.hasError('format')">
            {{ form.get(C_PASSWORD_REPEAT)?.getError('format').message }}
          </mat-error>
        </mat-form-field>
        <mat-error
          *ngIf="form.invalid && form.get(C_PASSWORD_REPEAT)?.valid && form.get(C_PASSWORD_NEW)?.valid"
          i18n="@@front-login.reset-pass.repeat-password-mismatch"
        >
          Las contraseñas no coinciden
        </mat-error>
      </div>
      <div>
        <button
          [disabled]="form.invalid || error"
          mat-raised-button
          color="primary"
          i18n="@@front-login.reset-pass.ok"
        >
          Aceptar
        </button>
      </div>
    </form>
  </div>
</div>
