export const MY_MAT_MOMENT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY'
  }
};

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
  || window.navigator.userAgent.indexOf('Trident/') > -1;

export class AzureConfig {
  // Howden Iberia => del registro de aplicaciones de Azure Active Directory del portal de Azure de Howden Iberia

  // Application (client) Id de Howden Iberia Security Front
  static readonly clientId_howdenIberia = 'ec6dbf7f-7ac1-4eb1-9e6e-908aa895432e';

  // Application (client) Id de Howden Iberia Security Back
  static readonly apiId_howdenIberia = 'a5a079f7-19c9-4107-8de9-e78c6ae8e872';

  // Howden Group => del registro de aplicaciones de Azure Active Directory del portal de Azure de Howden Group

  // Application (client) Id de Howden Iberia Security Front - Dev/Test
  static readonly clientId_howdenGroup = '797161ca-34c3-412c-9ee4-c3c4996b54c3';

  // Application (client) Id de Howden Iberia Security Back - Dev/Test
  static readonly apiId_howdenGroup = '2790d7fc-7085-428e-842c-14cf69c77d6d';

  // Application (client) Id de ARTAI Front - Dev/Test
  // static readonly clientId_howdenArtai = 'a0a0c8a5-a9ff-4966-8f69-6ea34e2dccab';
  static readonly clientId_howdenArtai = '99777d8a-f80e-4f16-9fcc-897ff9500a92';

  // Application (client) Id de ARTAI Back - Dev/Test
  // static readonly apiId_howdenArtai = 'c50e33d2-0b0b-4732-9e4b-a29134b20f13';
  static readonly apiId_howdenArtai = '4c051df7-8124-4ca9-b31a-87808af6a4c8';

  // force to local
  static readonly redirectUri = `${location.origin}/granted`;

  // force to be local
  static readonly keyUri = `${location.origin}`;
}
