import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EvaluationsService {
  stringIsNullOrEmpty(value: string): boolean {
    return !(!!value && value !== '' && value.length > 0);
  }

  dateValidNow(date: Date, minutesCourtesy = 20): boolean {
    const now2 = moment().add(minutesCourtesy, 'm').toDate();
    const date2 = new Date(date.toString());
    const res2 = date2.getTime() - (minutesCourtesy * 60000) - now2.getTime();

    return res2 > 0;
  }
}
