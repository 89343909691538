import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionsService, UserService } from '@app/core/services';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'howden-login-internal',
  templateUrl: './login-internal.component.html',
  styleUrls: ['./login-internal.component.scss']
})
export class LoginInternalComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private userSrv: UserService,
    private actionsSrv: ActionsService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
  }

  ngOnInit(): void {
    this.actionsSrv.changeLoading($localize`:@@front-login.login-internal.loading-message: Cargando ...`);
    this.userSrv.logout();
    this.instanceMsal();
    this.readParams();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private readParams() {
    const goingDirect = this.activatedRoute.snapshot.url[0].path.toLocaleLowerCase() === 'login-dir';

    this.activatedRoute.queryParamMap.subscribe((data: ParamMap) => {
      this.userSrv.setGoDirect(goingDirect);
      this.userSrv.setInternalLogin();

      const url = this.readCallBack(data);

      if (url !== undefined) {
        this.userSrv.setCallBack(url);
      }

      this.goToProcess();
    });
  }

  private instanceMsal(): void {
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();
    // Set the active account off
    this.authService.instance.setActiveAccount(null);

    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED)
    ).subscribe(() => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = '/';
      }
    });

    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe();
  }

  private goToProcess(): void {
    this.route.navigateByUrl('/process');
  }

  private readCallBack(params: ParamMap): string | undefined {
    if (!params.has('callback')) {
      return '';
    }

    const loginParams: [string] = ['callback'];

    let url = params.get('callback')?.toString();

    // try to get another params
    const anotherParams: string[] = [];

    params.keys.forEach((key: string) => {
      if (!loginParams.find(x => x === key)) {
        anotherParams.push(`&${key}=${params.get(key)}`);
      }
    });

    if (anotherParams.length > 0) {
      anotherParams.forEach(param => {
        url = `${url}${param}`;
      });
    }

    return url;
  }
}
