import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from '@app/app-routing.module';
import {
  HowdenAlertService,
  HowdenAlertsModule,
  HowdenAskForDataModule,
  HowdenAskForDataService,
  HowdenErrorPagesModule,
  HowdenYesNoModule
} from '@howdeniberia/core-front';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { GrantedComponent } from './granted/granted.component';
import { LogOutComponent } from './log-out/log-out.component';
import { LoginExternalComponent } from './login-external/login-external.component';
import { LoginInternalComponent } from './login-internal/login-internal.component';
import { ProcessComponent } from './process/process.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { ResultComponent } from './result/result.component';
import { SucessComponent } from './sucess/sucess.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';

@NgModule({
  declarations: [
    LoginInternalComponent,
    LoginExternalComponent,
    GrantedComponent,
    ProcessComponent,
    ResultComponent,
    SucessComponent,
    LogOutComponent,
    ResetPassComponent,
    UserPreferencesComponent,
    ForbiddenComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    HowdenAskForDataModule,
    HowdenAlertsModule,
    AppRoutingModule,
    ClipboardModule,
    HowdenErrorPagesModule,
    HowdenYesNoModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule
  ],
  exports: [LoginExternalComponent, ResetPassComponent],
  providers: [HowdenAskForDataService, HowdenAlertService]
})
export class PagesModule {
}
