<mat-card *ngIf="finished">
  <mat-card-header>
    <mat-card-title i18n-placeholder="@@front-login.result.title">Resultado {{ envName }}</mat-card-title>
    <mat-card-subtitle i18n-placeholder="@@front-login.result.subtitle">Información del Token</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div
      class="left-50 top-10 base"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="15px"
    >
      <div>authData Result</div>
      <div i18n-placeholder="@@front-login.result.user">user: {{ authData?.name }}</div>
      <div fxLayout="row" fxLayoutAlign=" start" fxLayoutGap="15px">
        <div class="texto">tokenAD: {{ authData?.tokenAD }}</div>
        <button
          mat-icon-button
          color="primary"
          (click)="copy(authData?.tokenAD)"
        >
          <mat-icon>copy</mat-icon>
        </button>
      </div>
      <span i18n-placeholder="@@front-login.result.tokenAD.expiration">
        tokenAD válido hasta: {{ authData?.tokenADValidDate | date : "yyyy/MM/dd hh:mm a" }}
      </span>
      <div fxLayout="row" fxLayoutAlign=" start" fxLayoutGap="15px">
        <div class="texto">
          tokenID: {{ authData?.tokenID }}
        </div>
        <button
          mat-icon-button
          color="primary"
          (click)="copy(authData?.tokenID)"
        >
          <mat-icon>copy</mat-icon>
        </button>
      </div>
      <span i18n-placeholder="@@front-login.result.tokenID.expiration">
        tokenID válido hasta: {{ authData?.tokenIDValidDate | date : "yyyy/MM/dd hh:mm a" }}
      </span>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign=" start" fxLayoutGap="15px">
        <div class="texto" i18n-placeholder="@@front-login.result.tokenID.refresh">
          tokenID refresh: {{ authData?.tokenIDRefresh }}
        </div>
        <button
          mat-icon-button
          color="primary"
          (click)="copy(authData?.tokenIDRefresh)"
        >
          <mat-icon>copy</mat-icon>
        </button>
      </div>
      <span i18n-placeholder="@@front-login.result.tokenID-refresh.expiration">
        tokenID refresh válido hasta: {{ authData?.tokenIDRefreshValidDate | date : "yyyy/MM/dd hh:mm a" }}
      </span>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <span>Develop pruposes</span>
        <div fxLayout="row" fxLayoutAlign=" start" fxLayoutGap="15px">
          <button mat-icon-button color="primary" (click)="copyUsrVar()">
            <mat-icon>copy</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <mat-form-field appearance="outline">
          <mat-label>Go To URL</mat-label>
          <input
            matInput
            i18n-placeholder="@@front-login.result.url.placeholder"
            placeholder="Pon la URL a la que quieras navegar"
            [formControl]="urlBack"
            required
            (ngModelChange)="size()"
          />
          <mat-hint>La Url</mat-hint>
          <span>size: {{ textSize }} sizeURI: {{ textSizeUrl }}</span>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <button mat-raised-button color="primary" (click)="navigate()">
            Navega
          </button>
          <button mat-raised-button (click)="clean()" color="warn" i18n-placeholder="@@front-login.result.delete-cookie">
            Borrar cookie
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
