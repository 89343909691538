import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from '@app/app-routing.module';
import { ErrorComponent } from './error/error.component';
import { FailedComponent } from './failed/failed.component';
import { RenewComponent } from './renew/renew.component';

@NgModule({
  declarations: [
    ErrorComponent,
    RenewComponent,
    FailedComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule
  ]
})
export class LayoutModule {
}
