<div class="main-external" fxLayout="col" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center start">
  <div fxFlex="600px" fxFlex.lt-md="100%" class="login-panel mat-elevation-z24">
    <img src="assets/images/logo-howden.png" class="howden-logo" alt="Howden Logo" />
    <form [formGroup]="formLogin" (ngSubmit)="login()">
      <h2 class="login-title">{{ title }}</h2>
      <div class="field">
        <label class="label" i18n="@@front-login.login-external.username">Nombre de usuario</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_USERNAME"
            i18n-placeholder="@@front-login.login-external.username-placeholder"
            placeholder="Introduzca su nombre de usuario"
          />
          <mat-error i18n="@@front-login.login-external.username-required" *ngIf="formLogin.get(C_USERNAME)?.invalid">
            El campo nombre de usuario es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <label class="label" i18n="@@front-login.login-external.password">Contraseña</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD"
            i18n-placeholder="@@front-login.login-external.password-placeholder"
            placeholder="Introduzca su contraseña"
            type="password"
          />
          <mat-error i18n="@@front-login.login-external.password-required" *ngIf="formLogin.get(C_PASSWORD)?.invalid">
            El campo contraseña es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <mat-error *ngIf="loginError" i18n="@@front-login.login-external.login-error">
        Usuario y/o contraseña inválidos
      </mat-error>
      <div>
        <button mat-raised-button color="primary" i18n="@@front-login.login-external.enter" [disabled]="formLogin.invalid">
          Entrar
        </button>
      </div>
      <div class="link-container">
        <a class="cursor-pointer" (click)="passForgotten()" i18n="@@front-login.login-external.password-forgotten">
          He olvidado mi contraseña
        </a>
      </div>
    </form>
  </div>
</div>
