import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import {
  AzureConfig,
  MY_MAT_MOMENT_DATE_FORMATS,
  isIE
} from '@constants/common-constants';
import { API_Config } from '@environment/api-configs';
import {
  BearerInterceptor,
  EnviromentZone,
  HowdenCoreModule,
  IPackageJsonWrapper
} from '@howdeniberia/core-front';
import { LayoutModule } from '@layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import packageJson from '../../package.json';
import { environment } from './../environments/environment';
import { PagesModule } from './pages/pages.module';

registerLocaleData(localeEs, 'es');

export function getAzureApiId(): string {
  if (environment.zone === EnviromentZone.DEVHOWDENGROUP
    || environment.zone === EnviromentZone.PREHOWDENGROUP
    || environment.zone === EnviromentZone.PROHOWDENGROUP) {
    return AzureConfig.apiId_howdenGroup;
  } else if (environment.zone === EnviromentZone.DEVHOWDENARTAI
    || environment.zone === EnviromentZone.PREHOWDENARTAI
    || environment.zone === EnviromentZone.PROHOWDENARTAI) {
    return AzureConfig.apiId_howdenArtai;
  }
  return AzureConfig.apiId_howdenIberia;
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (environment.zone !== EnviromentZone.PRODUCTION) {
    if (logLevel === LogLevel.Error) {
      console.error(message);
    } else if (logLevel === LogLevel.Info) {
      console.info(message);
    } else if (logLevel === LogLevel.Trace) {
      console.trace(message);
    } else if (logLevel === LogLevel.Warning) {
      console.warn(message);
    } else {
      console.log(message);
    }
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  let azureClientId = AzureConfig.clientId_howdenIberia;

  if (environment.zone === EnviromentZone.DEVHOWDENGROUP
    || environment.zone === EnviromentZone.PREHOWDENGROUP
    || environment.zone === EnviromentZone.PROHOWDENGROUP) {
    azureClientId = AzureConfig.clientId_howdenGroup;
  } else if (environment.zone === EnviromentZone.DEVHOWDENARTAI
    || environment.zone === EnviromentZone.PREHOWDENARTAI
    || environment.zone === EnviromentZone.PROHOWDENARTAI) {
    azureClientId = AzureConfig.clientId_howdenArtai;
  }

  const confAppAD = new PublicClientApplication({
    auth: {
      clientId: azureClientId,
      authority: `https://login.microsoftonline.com/organizations`,
      redirectUri: AzureConfig.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE  // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });

  return confAppAD;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const azureApiId = getAzureApiId();
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(AzureConfig.keyUri, [`api://${azureApiId}/user_impersonations`]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const azureApiId = getAzureApiId();
  const objScopes = {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: [`api://${azureApiId}/user_impersonations`] },
    loginFailedRoute: '/failed'
  } as MsalGuardConfiguration;

  return objScopes;
}

export const AppInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BearerInterceptor,
    multi: true
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  }
];

export const AppProviders: Provider[] = [
  { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
  { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
  // { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-icons-outlined' } },
  { provide: LOCALE_ID, useValue: 'es' },
  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  { provide: MAT_DATE_FORMATS, useValue: MY_MAT_MOMENT_DATE_FORMATS },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  MsalService,
  MsalGuard,
  MsalBroadcastService
];

export function CombineProviders(): Provider[] {
  const result: Provider[] = [...AppInterceptors, ...AppProviders];

  return result;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MsalModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    LayoutModule,
    SharedModule,
    PagesModule,
    HowdenCoreModule.forRoot(environment, packageJson as IPackageJsonWrapper, API_Config)
  ],
  providers: CombineProviders(),
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
